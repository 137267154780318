import { Controller } from "stimulus";

export default class extends Controller {

    connect() {

        this.element.addEventListener('click', this.onClick);
    }

    onClick(event) {
        let result = confirm('Сигурни ли сте че искате да извършите действието');
        if (!result) {
            event.preventDefault();
        }
    }
}
