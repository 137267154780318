import Vue from 'vue/dist/vue.esm.js';
import $ from "jquery";

Vue.component('add-to-cart-row', {
    template: `#add-to-cart-row`,
    delimiters: ['[[', ']]'],
    props: {
        addToCartUrl: {
            required: true,
            type: String,
        },
        context: {
            required: true,
            type: String,
        },
        variant: {
            required: false,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        hasUser: {
            required: false,
            type: Number,
            default: false,
        },
        attributesValuesCount: {
            required: false,
            type: Number,
            default: false,
        },
    },


    mounted() {
        this.selectedVariant = this.variant;
        //TODO select from user settings
        this.selectedStore = this.variant.stores[0];
        this.fixTableRendering();
        EventManager.listen('add-all-rows', this.addToCart);
        EventManager.listen('variant-change', this.updateVariantData);
        EventManager.listen('variant-change-max-quantity', this.updateVariantMaxQuantity);
    },

    data() {
        return {
            quantity: 0,
            maxQuantityReached: false,
            selectedStore: null,
            emptyColumnsCount: (this.attributesValuesCount - this.variant.options.length)
        }
    },

    methods: {
        buildAttributes() {

            let _self = this;
            this.variants.map(function (variant) {

                let selected = variant.options.length === 1;

                variant.options.map(function (option) {
                    let attributeData = _self.attributes.filter(a => a.slug === option.attribute.slug)[0];

                    if (attributeData === undefined) {
                        attributeData = {
                            availableOptions: [],
                            name: option.attribute.name,
                            slug: option.attribute.slug,
                            showUnit: option.attribute.showUnit,
                            orderStrategy: option.attribute.orderStrategy,
                            image: option.attribute.image,
                            selected: true,
                            selectedValue: null
                        };

                        _self.attributes.push(attributeData);
                    }

                    option.value.unit = variant.unit;
                    option.value.unitDiscount = variant.unitDiscount;
                    option.value.price = variant.price;
                    option.value.quantity = variant.quantity;
                    if (attributeData.availableOptions.filter(v => v.id === option.value.id && v.unit === option.value.unit).length === 0) {
                        //option.value.selected = selected;
                        attributeData.availableOptions.push(option.value);
                    }

                })
            });


        },

        addToCart() {
            if (this.quantity == 0) {
                return;
            }

            let formData = [];
            this.sendAddToCartRequest(formData);
        },

        updateVariantData(changedVariant){
            if (this.variant.id == changedVariant.id){
                this.variant = changedVariant;
            }
        },

        updateVariantMaxQuantity(changedVariantData){
            if (this.variant.id == changedVariantData.variantId){
                if (changedVariantData.newMaxQuantity <= this.variant.maxQuantity) {
                    this.quantity = 0;
                }
                this.variant.maxQuantity = changedVariantData.newMaxQuantity;
                this.variant.quantityInOtherWidget = this.variant.quantity + changedVariantData.newMaxQuantity;
            }
        },

        sendAddToCartRequest() {

            let additionalData = {};

            let _self = this;
            $.ajax({
                data: {
                    id: _self.selectedVariant.id,
                    storeId: _self.selectedStore.id,
                    quantity: _self.quantity,
                    additionalData: JSON.stringify(additionalData)
                },
                url: _self.addToCartUrl,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        fixSelectedQuantity() {
            let maxQuantity = this.selectedVariantQuantity;

            if (this.quantity >= maxQuantity) {
                this.quantity = maxQuantity;
            }else{
                this.quantity = Math.floor(this.quantity / this.getSelectedVariantQuantityStep()) * (this.getSelectedVariantQuantityStep());
                this.quantity = parseInt(this.quantity);

                if (this.quantity < this.getSelectedVariantQuantityStep() && this.quantity > 1) {
                    this.quantity = this.getSelectedVariantQuantityStep()
                }

                if (this.quantity >= maxQuantity) {
                    this.quantity = maxQuantity;
                }
            }
        },
        selectStore(store) {
            this.variant.quantityInOtherWidget = 0;
            this.selectedStore = store;
            this.fixSelectedQuantity();
        },
        incrementQuantity() {
            let maxQuantity = this.selectedVariantQuantity();
            if (this.quantity >= maxQuantity) {
                return;
            }
            this.quantity = parseInt(this.quantity) + this.getSelectedVariantQuantityStep();
            if (this.quantity > maxQuantity) {
                this.quantity = parseInt(maxQuantity);
            }
        },
        changeQuantity() {
            if (this.quantity <= 0) {
                this.quantity = 0;
            }
            let maxQuantity = this.selectedVariantQuantity();

            if (this.quantity > maxQuantity) {
                this.quantity = parseInt(maxQuantity);
            }

            this.fixSelectedQuantity();
        },
        decrementQuantity() {
            if ((this.quantity - this.getSelectedVariantQuantityStep()) < 0) {
                this.quantity = 0;
                return;
            }
            this.quantity -= this.getSelectedVariantQuantityStep();
            this.fixSelectedQuantity();
        },
        formattedPrice(price) {
            let formatedPrice = new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
            return formatedPrice.replace(',','.');
        },
        successAjax(result) {
            let _self = this;
            // this.maxQuantityReached = false;

            let successMessage = $('#success');
            let addRowMessage = $('#add-row-message');
            let failMessage = $('#fail');
            this.quantity = 0;
            addRowMessage.hide();

            if (result.success === false) {
                addRowMessage.text(result.message);
                addRowMessage.show();
                setTimeout(function (){
                    addRowMessage.hide();
                }, 5000);
                this.maxQuantityReached = true;
                return;
            } else {
                successMessage.show();
                failMessage.hide();
            }

            // let modal = $('#checkout-modal');
            // modal.modal('show');
            // let cartWidget = $('#myCart');
            // cartWidget.trigger('click');
            $(".product-alert").addClass("active");
            setTimeout(function (){
                $(".product-alert").removeClass("active");
            }, 5000);
            // window.scrollTo({ top: 0, behavior: 'instant' });

            EventManager.fire('item-added', result.cart);

            let currentCart = JSON.parse(result.cart);
            var currentVariantInCart = currentCart.rows.map(function (row){
                if(row.variantId = _self.variant.id && _self.selectedStore.id == currentCart.selectedStore.id){
                    return row.quantity;
                }
            })
            _self.variant.quantityInCart[_self.selectedStore.id] = currentVariantInCart;

            let event = {
                event: 'eec.add',
                eventData: result.variant,
                unique_event_id: result.unique_event_id,
                action: _self.context,
            }
            EventManager.fire('gtm-event', event);
        },
        changeVariantInMainOption(variantSku){
            window.scrollTo({ top: 0, behavior: 'instant' });
            EventManager.fire('change-variant', variantSku);
        },
        selectedVariantQuantity() {
            let quantityInCart = this.variant.quantityInCart[this.selectedStore.id];
            // let maxQuantityLimit = this.selectedStore.quantity;
            return this.selectedStore.quantity - quantityInCart;
            // let quantityInOtherWidget = this.variant.quantityInOtherWidget ?? 0;
            //
            // if (this.selectedStore.quantity - quantityInCart > maxQuantityLimit && maxQuantityLimit > 0) {
            //     return maxQuantityLimit;
            // }
            // return this.selectedStore.quantity - quantityInCart - quantityInOtherWidget;
        },
        fixTableRendering() {
            $('.table-row-scroll').each(function () {
                $('#fast-order-table').append($(this));
            })
        },
        updateRequestForm(variantId, storeId){
            document.getElementById("variant_id").value = variantId;
            document.getElementById("store_id").value = storeId;
        },
        getSelectedVariantQuantityStep() {
            if(this.hasUser){
                return this.selectedVariant.quantityStepTrader;
            }else{
                return this.selectedVariant.quantityStep;
            }
        }

    },

    computed: {
        selectedVariantPrice() {
            if (this.selectedVariant) {
                return this.formattedPrice(this.selectedVariant.price);
            } else {
                return this.formattedPrice(this.defaultVariant.price);
            }
        },
        selectedVariantDiscountedPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.discountedPrice) {
                return this.formattedPrice(variant.discountedPrice);
            } else {
                return false;
            }
        }
    }
});