import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.loadCheckoutEvent({
            'id': this.element.getAttribute('data-id'),
            'name': this.element.getAttribute('data-name')
        });
    }

    //step 1
    loadCheckoutEvent(data) {
        EventManager.fire('load-event-checkout', data);
    }

    changeUserData() {
        EventManager.fire('load-event-checkout-client-data', null);
    }

    agreeTerms() {
        EventManager.fire('load-event-checkout-terms', null);
    }

}

