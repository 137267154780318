import Vue from 'vue/dist/vue.esm.js';

Vue.component('flash', {
    template: '#flash',
    props: {
        'message': {
            required: true,
            type: String
        },
        'level': {
            required: false,
            type: String,
            default: 'success'
        }
    },
    data() {
        return {
            messageClass: 'success',
            show: false,
            hasBeenShown: false
        }
    },
    created() {
        this.messageClass = this.getMessageClass(this.level);

        this.flash();
    },
    methods: {
        flash() {
            if (this.hasBeenShown) {
                return;
            }

            this.messageClass = this.getMessageClass(this.level);
            this.show = true;
            this.hide();
        },

        hide() {
            this.hasBeenShown = true;

            setTimeout(() => {
                this.show = false;
            }, 8000);
        },

        getMessageClass(level) {
            if (level === 'error') {
                return 'danger';
            } else if (level === 'warning') {
                return 'warning';
            }else {
                return 'success';
            }
        }
    }
});