import Vue from 'vue/dist/vue.esm.js';
import Splide from "@splidejs/splide";

Vue.component('product-gallery', {
    template: `#product-gallery`,
    delimiters: ['[[', ']]'],
    props: {
        product: {
            required: true,
            type: Object,
        },
    },

    mounted() {
        this.initSlides();
        EventManager.listen('gallery-changed', this.galleryChanged);
    },

    data() {
        return {
            gallery: this.product.gallery,
            reinit: false,
        }
    },

    methods: {
        initSlides() {
            this.reinit = false;
            let _self = this;
            this.$nextTick(() => {
                this.reinit = true;
                this.$nextTick(() => {
                    let splide = new Splide('#main-slider', {
                        width: 650,
                        autoHeight: true,
                        pagination: false,
                        arrows: false,
                        nav: false,
                        cover: true
                    });

                    const thumbnails = document.querySelectorAll(".thumbnail");
                    let current;
                    for (let i = 0; i < thumbnails.length; i++) {
                        thumbnails[i].addEventListener("click", (event) => {
                            splide.go(i);
                        }, false);
                    }

                    splide.on("mounted move", function () {
                        const thumbnail = thumbnails[splide.index];
                        if (thumbnail) {
                            if (current) {
                                current.classList.remove("is-active");
                            }
                            thumbnail.classList.add("is-active");
                            current = thumbnail;
                        }
                    });
                    splide.mount();

                });

            });

        },

        galleryChanged(data) {
            this.gallery = data;
            this.initSlides();
        },
    },

    computed: {}
});