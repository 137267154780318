import Vue from 'vue/dist/vue.esm.js';

Vue.component('cart', {
    template: `#cart`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        hasUser: {
            required: true,
            type: Number,
        },
        stores: {
            required: false,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        changeQuantityUrl: {
            required: true,
            type: String,
        },
        changeStoreUrl: {
            required: true,
            type: String,
        },
        clearCartUrl: {
            required: true,
            type: String,
        },
        deliveryOption: {
            required: false,
            type: Object,
        }
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
        EventManager.listen('store-change', this.cartUpdated);
        EventManager.listen('quantity-change', this.cartUpdated);

        this.selectedStore = this.cart.selectedStore;

        this.checkForEmptyCart();
        this.cart.taxPrice = this.calculatedTaxPrice();
    },

    data() {
        return {
            cart: this.initialCart,
            selectedStore: null,
            quantityAlert: {
                visible: false,
                productName: '',
            },
        }
    },

    methods: {
        cartUpdated(data) {
            this.cart = JSON.parse(data);
            this.selectedStore = this.cart.selectedStore;
            this.cart.taxPrice = this.calculatedTaxPrice();
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event',event);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId, storeId: row.store.id},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if(result.success !== false){
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);

                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            unique_event_id: result.unique_event_id,
                            action: 'CartPage',
                        }

                        EventManager.fire('gtm-event', event);

                    }else{
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event',event);
                    _self.checkForEmptyCart();
                },
            });
        },
        changeCartStore() {
            let _self = this;
            $.ajax({
                data: {id: _self.selectedStore.id},
                url: _self.changeStoreUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('store-change', result.cart);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > row.variantMaxQuantity) {
                return;
            }
            this.addVariantQuantity(row);
        },
        changeRowQuantity(row){

            let _self = this;
            $.ajax({
                data: {id: row.id, quantity: row.quantity},
                url: _self.changeQuantityUrl,
                method: 'POST',
                success: function (result) {
                    if(result.cart){
                        EventManager.fire('quantity-change', result.cart);
                    }
                },
            });
        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.totalPrice);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        qualifiesForFreeDelivery() {
            return (this.cart.hasFreeDelivery);
        },
        outOfStockProducts(){
            return this.selectedStoreRows.filter(row => !row.inStock);
        },

        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index,attribute] of attributes.entries()) {
                if(index !== 0){
                    str += ', '
                }
                if(attribute.showUnit){
                    str += attribute.unit + ' x '
                }
                str +=  ' ' + attribute.value;

            }
            return str;
        },
        selectStore(store){
            this.selectedStore = store;
            this.changeCartStore();
        },
        clearCart(){
            let _self = this;
            $.ajax({
                data: {id: _self.selectedStore.id},
                url: _self.clearCartUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);
                    _self.checkForEmptyCart();
                },
            });
        },
        hasRowsForStore(store){
            if(this.cart.rows.length == 0){
                return false;
            }
            return this.cart.rows.filter(row => row.store.id == store.id).length > 0;
        },
        tryAutoSelectDifferentStore() {
            if (this.selectedStore) {
                let _self = this;
                var otherStoreRows = this.cart.rows.filter(row => row.store.id != _self.selectedStore.id);
                if (otherStoreRows.length > 0) {
                    this.selectedStore = otherStoreRows[0].store;
                    this.changeCartStore();
                }
            }
        },
        checkForEmptyCart(){
            if(!this.hasRowsForStore(this.selectedStore)){
                this.tryAutoSelectDifferentStore();
            }
        },
        calculatedTaxPrice() {
            let _self = this;
            if (this.hasUser) {
                var taxPriceSettings = this.deliveryOption.taxPrices.filter(taxPriceSettings => (taxPriceSettings.storeId == _self.cart.selectedStore.id));

                if (taxPriceSettings) {
                    taxPriceSettings = taxPriceSettings[0];
                }
                var taxPrice = 0;
                if (taxPriceSettings.minTotalForFreeTax >= this.cart.total) {
                    taxPrice = taxPriceSettings.taxPrice;
                }
            } else {
                taxPrice = this.deliveryOption.price;
            }

            return taxPrice;
        },
    },

    computed: {
        formattedTotal() {
            return this.formattedPrice(this.cart.total)
        },
        selectedStoreRows(){
            let _self = this;
            if(!_self.selectedStore){
                return this.cart.rows;
            }
            if(this.cart.rows.length == 0){
                return [];
            }
            return this.cart.rows.filter(row => row.store.id === _self.selectedStore.id);
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.totalPriceAfterDiscount)
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        formattedTaxPrice() {
            var taxPrice = this.calculatedTaxPrice();

            return this.formattedPrice(taxPrice);
        },
    }
});