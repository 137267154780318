import Vue from 'vue/dist/vue.esm.js';

Vue.component('search-autocomplete', {
    template: `#search-autocomplete`,
    delimiters: ['[[', ']]'],
    props: {
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        searchUrl: {
            required: true,
            type: String,
        },
        searchPageUrl: {
            required: true,
            type: String,
        },
    },

    mounted() {

    },

    data() {
        return {
            results: [],
            term: '',
            timeout: 0,
            moreResultCount: 0,
            isLoading: false,
            isDropdownVisible: false,
        }
    },

    methods: {
        documentOnClick() {
            this.hide();
        },
        show() {
            this.isDropdownVisible = true;
            document.addEventListener("click", this.documentOnClick);
        },
        hide() {
            this.isDropdownVisible = false;
            document.removeEventListener("click", this.documentOnClick);
        },
        inputOnClick(e){
            e.stopPropagation();
        },
        containerOnClick(e){
            e.stopPropagation();
        },
        onFocus(){
            this.show();
        },

        onKeyUp() {
            this.isLoading = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.search, 300);
        },

        search() {

            this.results = [];
            let _self = this;

            if (this.term === '') {
                return;
            }
            $.ajax({
                data: {term: _self.term},
                url: _self.searchUrl,
                method: 'GET',
                success: function (result) {
                    _self.isLoading = false;
                    _self.results = result.products;
                    _self.moreResultCount = result.moreResultCount;
                },
            });
        },


        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

    },

    computed: {
        fullSearchUrl() {
            return this.searchPageUrl + '?term=' + this.term;
        }
    }
});