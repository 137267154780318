import Vue from 'vue/dist/vue.esm.js';

Vue.component('event-checkout', {
    template: '#event-checkout',
    props: {
        initialQuantity: {
            required: true,
            type: Number,
            default: 1
        },
        maxQuantity: {
            required: true,
            type: Number,
            default: 1
        },
        fixedDiscounts: {
            required: true,
            type: Array,
            default: []
        },
        singleTicketPrice: {
            required: true,
            type: Number,
            default: 1
        },
        locale: {
            required: true,
            type: String,
            default: 'bg'
        },
        currency: {
            required: true,
            type: String,
            default: 'bg'
        },
    },
    data() {
        return {
            quantity: this.initialQuantity,
            ticketPrice: this.singleTicketPrice,
        }
    },
    mounted() {

    },

    methods: {
        incrementQuantity() {
            if (this.quantity > 99 || this.quantity >= this.maxQuantity) {
                return;
            }
            this.quantity = parseInt(this.quantity) + 1;

            this.calcDiscounts();
        },
        decrementQuantity() {
            if (this.quantity === 1) {
                return;
            }
            this.quantity = parseInt(this.quantity) - 1;
            this.calcDiscounts();
        },

        calcDiscounts() {

            if (this.fixedDiscounts.length > 0) {
                for (let each of this.fixedDiscounts) {
                    if (each.discountCondition === 'eq' && this.quantity === each.quantity) {
                        this.ticketPrice = each.price;
                        break;
                    }

                    if (each.discountCondition === 'gt' && this.quantity > each.quantity) {
                        this.ticketPrice = each.price;
                        break;
                    }

                    this.ticketPrice = this.singleTicketPrice;
                }
            }
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

    },
    computed: {
        total() {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(this.quantity * this.ticketPrice);
        }

    }
});