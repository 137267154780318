import Vue from 'vue/dist/vue.esm.js';
import {indexOf} from "core-js/internals/array-includes";
import {trim} from "core-js/internals/string-trim";
import $ from "jquery";


Vue.component('add-to-cart', {
    template: `#add-to-cart`,
    delimiters: ['[[', ']]'],
    props: {
        addToCartUrl: {
            required: true,
            type: String,
        },
        getProductSerializeUrl: {
            required: true,
            type: String,
        },
        context: {
            required: true,
            type: String,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        useFirstVariant: {
            required: false,
            type: Boolean,
            default: false,
        },
        userIsTrader: {
            required: false,
            type: Number,
            default: false,
        },
    },

    beforeMount() {
        let _self = this;
        EventManager.listen('change-variant', this.changeVariantBySku);

        $.ajax({
            url: this.getProductSerializeUrl,
            method: 'GET',
            processData: false,
            contentType: false,
            success: function (response) {
                _self.product = JSON.parse(response);
                _self.mountedAfterAjax();
            },
            error: function (error) {
                console.log(error);
            }
        });
    },

    data() {
        return {
            selectedOptions: [],
            defaultVariant: [],
            selectedVariant: null,
            variants: [],
            attributes: [],
            gallery: [],
            availableVariants: [],
            quantity: 1,
            product: [],
            maxQuantityReached: false,
            variantsCount: 0,
            availableForAdd: true,
            selectedStore: null,
            quantityErrorText: '',
        }
    },

    methods: {
        mountedAfterAjax() {
            let _self = this;
            console.log(this.product);
            this.defaultVariant = this.product.defaultVariant;
            this.variants = this.product.variants;
            this.gallery = this.product.gallery;
            this.availableVariants = this.product.variants;
            console.log(this.availableVariants);
            this.variantsCount = this.product.variantsCount;
            this.buildAttributes();

            if (this.product.variants.length === 1) {
                this.selectedVariant = this.defaultVariant;
                this.quantity = this.getSelectedVariantQuantityStep();

                for (let attribute of this.attributes) {

                    for (let option of attribute.availableOptions) {
                        option.selected = true;
                    }
                }
                // if(this.selectedVariant.quantity === 0)
                //     this.quantity = this.selectedVariant.quantity;
            }

            if (this.selectedVariant && this.selectedVariant.options && this.selectedVariant.options.length > 0) {
                this.selectedVariant.options[0].value.selected = true;
                this.refreshAvailableAttributeOptions();
                this.checkUnselectedAttributes()
                this.updateSku();
                this.updateGallery();
            }
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let wantedSku = urlParams.get('sku');
            let variant = this.selectedVariant ?? this.defaultVariant;
            if(wantedSku){
                variant = this.variants.filter(v => v.sku === wantedSku)[0];
                this.selectedVariant = this.variants.filter(v => v.sku === wantedSku)[0];

                this.selectedVariant.options.forEach(function (availableOption){
                    console.log(availableOption.value.value);
                    for (let attribute of _self.attributes) {
                        for (let option of attribute.availableOptions) {
                            if(option.id == availableOption.value.id){
                                console.log(option);
                                option.selected = true;
                                option.active = true;
                            }
                        }
                    }
                })
                // if(this.selectedVariant.quantity === 0)
                //     this.quantity = this.selectedVariant.quantity;
            }
            console.log(this.selectedVariant);

            if (this.selectedVariant) {
                this.selectedStore = variant.stores[0];
                this.checkAvailability();
            } else {
                $('.in-stock').hide();
            }

        },
        changeVariantBySku(variantSku){
            for(let attribute of this.attributes){
                for (let selectedOption of attribute.availableOptions) {
                    let index = this.selectedOptions.indexOf(selectedOption);
                    selectedOption.selected = false;
                    if (index > -1) { // only splice array when item is found
                        this.selectedOptions.splice(index, 1);
                    }
                }
            }
            let _self = this;
            this.selectedVariant = this.variants.filter(v => v.sku === variantSku)[0];

            this.selectedVariant.options.forEach(function (availableOption){
                for (let attribute of _self.attributes) {
                    for (let option of attribute.availableOptions) {
                        if(option.id == availableOption.value.id){
                            console.log(option);
                            option.selected = true;
                            option.active = true;
                        }
                    }
                }
            })
            // if(this.selectedVariant.quantity === 0)
            //     this.quantity = this.selectedVariant.quantity;

            EventManager.fire('slide-to-variant-picture', this.selectedVariant.mediaItemPosition);
        },
        buildAttributes() {

            let _self = this;
            this.variants.map(function (variant) {

                let selected = variant.options.length === 1;

                variant.options.map(function (option) {
                    let attributeData = _self.attributes.filter(a => a.slug === option.attribute.slug)[0];

                    if (attributeData === undefined) {
                        attributeData = {
                            availableOptions: [],
                            id: option.attribute.id,
                            name: option.attribute.name,
                            slug: option.attribute.slug,
                            showUnit: option.attribute.showUnit,
                            orderStrategy: option.attribute.orderStrategy,
                            image: option.attribute.image,
                            selected: true,
                            selectedValue: null
                        };

                        _self.attributes.push(attributeData);
                    }

                    option.value.unit = variant.unit;
                    option.value.unitDiscount = variant.unitDiscount;
                    option.value.price = variant.price;
                    option.value.quantity = variant.quantity;
                    if (attributeData.availableOptions.filter(v => v.id === option.value.id && v.unit === option.value.unit).length === 0) {
                        //option.value.selected = selected;
                        attributeData.availableOptions.push(option.value);
                    }

                })
            });


        },

        selectAttribute(option, attribute) {
            let _self = this;

            //Селектване на избраната опция
            option.selected = !option.selected;
            if (option.selected) {
                attribute.selectedValue = option.label ? option.label : option.value;
            } else {
                attribute.selectedValue = null;
            }

            if (option.selected) {
                this.selectedOptions.push(option);
            }

            //Премахване на стара избрана опция от същият атрибут.
            attribute.availableOptions.map(function (o) {
                if ((o.id !== option.id) || (o.id === option.id && o.unit !== option.unit)) {
                    o.selected = false;
                    _self.selectedOptions = _self.selectedOptions.filter(op => op.selected);
                }
            });

            //Вземаме варянтите с текущата избрана опция
            let tempAvailableVariants = [];
            this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, [option])) {
                    tempAvailableVariants.push(variant);
                }
            });

            //деселектваме опциите, които не принадлежат на някой от възможните варянти.
            this.deselectOptions(tempAvailableVariants);

            //Вземаме варянтите с останалите избрани опции, които не са махнати на предишната стъпка.
            tempAvailableVariants = [];
            this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, _self.selectedOptions)) {
                    tempAvailableVariants.push(variant);
                }
            });

            //деселектваме опциите, които не принадлежат на някой от възможните варянти.
            this.deselectOptions(tempAvailableVariants);

            //Забраняваме всички опции, които не принадлежат на някой от варянтите, и разрешаване на останалите.
            for (let attr of this.attributes) {
                for (let option of attr.availableOptions) {
                    let isNotAvailableOption = true;
                    for (let tempVariant of tempAvailableVariants) {
                        for (let vOption of tempVariant.options) {
                            if (option.id === vOption.value.id) {
                                isNotAvailableOption = false;
                                break;
                            }
                        }
                    }

                    option.available = !isNotAvailableOption;
                }
            }

            //Ако имаме само една разрешена опция за атрибут, я избираме автоматично.
            for (let attr of this.attributes) {
                let selectCandidate = null;
                for (let option of attr.availableOptions) {
                    if (option.available === true)
                        if (selectCandidate === null) {
                            selectCandidate = option;
                        } else {
                            selectCandidate = null;
                            break;
                        }
                }

                if (selectCandidate !== null) {
                    selectCandidate.selected = true;
                    this.selectedOptions.push(selectCandidate);
                }
            }

            //Ако имаме 1 вариант възможен го избираме автоматично.
            if (tempAvailableVariants.length === 1) {
                this.selectedVariant = tempAvailableVariants[0];
                this.quantity = this.getSelectedVariantQuantityStep();
                this.maxQuantityReached = false;
                this.fixSelectedQuantity();

                //Ако няма снимка на варянта, водим до дефолтната снимка.
                //Ако има снимка вземаме нея.
                EventManager.fire('variant-change-max-quantity', {variantId:this.selectedVariant.id,newMaxQuantity:(this.selectedVariant.maxQuantity - this.quantity)});
                EventManager.fire('slide-to-variant-picture', this.selectedVariant.mediaItemPosition);

            }

            if(this.selectedVariant){
                this.selectedStore = this.selectedVariant.stores[0];
            }

            this.updateSku();
            this.checkAvailability();
            this.updateGallery();
            this.checkStockStatus();
        },

        deselectOptions(tempAvailableVariants) {
            let deselectedOptions = [];
            for (let selectedOption of this.selectedOptions) {
                let needDeselectOption = true;
                for (let tempVariant of tempAvailableVariants) {
                    for (let tempVariantOption of tempVariant.options) {
                        if (selectedOption.id === tempVariantOption.value.id) {
                            needDeselectOption = false;
                        }
                    }
                }

                if (needDeselectOption) {
                    deselectedOptions.push(selectedOption);
                }
            }

            for (let deselected of deselectedOptions) {
                let index = this.selectedOptions.indexOf(deselected);
                if (index > -1) { // only splice array when item is found
                    deselected.selected = false;
                    this.selectedOptions.splice(index, 1);
                }
            }
        },

        resetAllExcept(lastOption) {
            for (let selectedOption of this.selectedOptions) {
                if (!this.matchOption(selectedOption, lastOption)) {
                    // selectedOption.selected = false;
                }
            }

            this.selectedOptions = [lastOption];

        }
        ,
        checkAvailability() {
            if (this.selectedVariant === null || this.selectedVariant.quantity <= 0) {
                $('.in-stock').hide();
                $('.not-in-stock').show();
            } else {
                $('.in-stock').show();
                $('.not-in-stock').hide();
            }
        }
        ,
        updateSku() {
            let sku = this.selectedVariant ? this.selectedVariant.sku : this.product.sku;
            $('#product-sku').text(sku);

        }
        ,

        updateGallery() {
            let gallery = this.selectedVariant ? this.selectedVariant.gallery : this.product.gallery;
            if (this.gallery != gallery) {
                this.gallery = gallery;
                EventManager.fire('gallery-changed', this.gallery);
            }

        }
        ,

        checkStockStatus() {
            let isAvaiableCombination = true;
            for (let key in this.selectedOptions) {
                let option = (this.selectedOptions[key]);
                if (!option.available) {
                    isAvaiableCombination = false;
                }
            }
            if (this.attributes.length == 1) {
                isAvaiableCombination = this.selectedOptions[0].quantity > 0;
            }
            if (this.selectedOptions.length == 0) {
                this.availableForAdd = true;
            } else if (isAvaiableCombination) {
                this.availableForAdd = true;
            } else {
                this.availableForAdd = false;
            }

        }
        ,
        refreshAvailableAttributeOptions() {

            for (let attribute of this.attributes) {
                for (let option of attribute.availableOptions) {
                    option.available = this.isOptionAvailable(attribute, option);
                }
            }
        }
        ,

        isOptionAvailable(attribute, option) {
            if (this.attributes.length == 1) {
                return true;
            }
            let remainingVariants = this.variants.filter(v => this.variantHasAttributeValue(v, option));

            for (let eachAttribute of this.attributes) {
                if (eachAttribute === attribute) {
                    continue;
                }

                for (let selectedOption of eachAttribute.availableOptions.filter(o => o.selected)) {
                    remainingVariants = remainingVariants.filter(v => this.variantHasAttributeValue(v, selectedOption));
                }
            }

            return remainingVariants.length > 0;
        }
        ,

        variantHasAllAttributeValues(variant, selectedOptions) {

            for (let selectedOption of selectedOptions) {
                if (!this.variantHasAttributeValue(variant, selectedOption)) {
                    return false;
                }
            }

            return true;
        }
        ,

        variantHasAttributeValue(variant, selectedOption) {

            for (let option of variant.options) {

                if (option.value.id === selectedOption.id && option.value.unit === selectedOption.unit) {
                    return true;
                }
            }
            return false;
        }
        ,

        matchOption(a, b) {
            return a.id === b.id && a.unit === b.unit;
        }
        ,

        sortedAttributeAvailableOptions(attribute) {

            switch (attribute.orderStrategy) {
                case 0:
                    return attribute.availableOptions;
                case 1:
                    return attribute.availableOptions.slice().sort((a, b) => {
                        return a.value.localeCompare(b.value);
                    });
                case 2:
                    return attribute.availableOptions.slice().sort((a, b) => {
                        return -a.value.localeCompare(b.value);
                    });
                case 3:
                    return this.sortDressSizes(attribute);
                case 4:
                    return this.sortHookSizes(attribute);
                default:
                    console.error('Invalid attribute orderStrategy value');
            }

        }
        ,

        sortDressSizes(attribute) {
            return attribute.availableOptions.slice().sort((a, b) => {
                const sizes = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];
                return sizes.indexOf(a.value) - sizes.indexOf(b.value);
            });
        }
        ,

        sortHookSizes(attribute) {
            function getRank(name) {
                if (name.slice(-2) === "/0") {
                    const numeralPart = name.slice(0, name.indexOf('/') + 1);
                    return -parseInt(numeralPart) * 1000;
                }

                if (name[0] === "0") {
                    return -name.length;
                }

                return parseInt(name);
            }

            function compare(a, b) {
                return getRank(a.value) - getRank(b.value);
            }

            return attribute.availableOptions.slice().sort(compare);
        }
        ,
        attributeComparer(a, b) {

            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;

        }
        ,
        addToCart() {
            if (!this.selectedVariant) {
                this.checkUnselectedAttributes();
                return;
            }
            let container = this.$refs.personalisationFormContainer;

            let formData = [];

            if (container) {
                let personalisationForm = container.querySelector('form');
                if (!personalisationForm.checkValidity()) {
                    personalisationForm.reportValidity();
                    return;
                }

                let submitUrl = personalisationForm.dataset.submitUrl,
                    formData = new FormData(personalisationForm)

                let _self = this;
                $.ajax({
                    url: submitUrl,
                    data: formData,
                    method: 'POST',
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        if (response.success) {
                            container.innerHTML = response.content;
                            _self.sendAddToCartRequest(formData);
                        } else {
                            container.innerHTML = response.content;
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });

            } else {
                this.sendAddToCartRequest(formData);
            }


        }
        ,

        sendAddToCartRequest(formData) {

            let additionalData = {};
            if (formData instanceof FormData) {
                formData.forEach(function (value, index) {
                    if (!index.includes("_token"))
                        additionalData[index] = value;
                })
            }

            let _self = this;
            if(!this.selectedStore){
                this.selectedStore = this.selectedVariant.stores[0];
            }
            $.ajax({
                data: {
                    id: _self.selectedVariant.id,
                    storeId: _self.selectedStore.id,
                    quantity: _self.quantity,
                    additionalData: JSON.stringify(additionalData)
                },
                url: _self.addToCartUrl,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        }
        ,

        checkUnselectedAttributes() {
            for (let attribute of this.attributes) {
                if (attribute.availableOptions.filter(a => a.selected === true).length === 0) {
                    attribute.selected = false;
                } else {
                    attribute.selected = true;
                }
            }
        }
        ,
        fixSelectedQuantity() {
            let maxQuantity = this.selectedVariantQuantity;

            if (this.quantity >= maxQuantity) {
                this.quantity = maxQuantity;
            }else{
                this.quantity = Math.floor(this.quantity / this.getSelectedVariantQuantityStep()) * (this.getSelectedVariantQuantityStep());
                this.quantity = parseInt(this.quantity);

                if (this.quantity < this.getSelectedVariantQuantityStep()) {
                    this.quantity = this.getSelectedVariantQuantityStep()
                }

                if (this.quantity >= maxQuantity) {
                    this.quantity = maxQuantity;
                }
            }

            if(this.quantity < 1){
                this.quantity = 1;
            }
            if (this.quantity < 1 && this.selectedVariantQuantity > 0) {
                this.quantity = (this.getSelectedVariantQuantityStep());
                this.incrementQuantity();
            }
        },
        incrementQuantity() {
            let maxQuantity = this.selectedVariantQuantity ?? 99;
            if (this.quantity > maxQuantity) {
                return;
            }
            this.quantity = parseInt(this.quantity) + this.getSelectedVariantQuantityStep();
            if (this.quantity > maxQuantity) {
                this.quantity = parseInt(maxQuantity);
            }
            EventManager.fire('variant-change-max-quantity', {variantId:this.selectedVariant.id,newMaxQuantity:(this.selectedVariant.maxQuantity - this.quantity)});
        }
        ,

        decrementQuantity() {
            if (this.quantity === 1) {
                return;
            }
            if (this.quantity === 0) {
                return;
            }
            this.quantity -= this.getSelectedVariantQuantityStep();
            this.fixSelectedQuantity();
            EventManager.fire('variant-change-max-quantity', {variantId:this.selectedVariant.id,newMaxQuantity:(this.selectedVariant.maxQuantity - this.quantity)});
        }
        ,
        changeQuantity() {
            if (this.quantity <= 0) {
                this.quantity = 0;
            }
            this.fixSelectedQuantity();

            EventManager.fire('variant-change-max-quantity', {variantId:this.selectedVariant.id,newMaxQuantity:(this.selectedVariant.maxQuantity - this.quantity)});
        },

        formattedPrice(price) {
            let formatedPrice = new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
            return formatedPrice.replace(',','.');
        }
        ,

        successAjax(result) {
            let _self = this;
            this.maxQuantityReached = false;

            let successMessage = $('#success');
            let failMessage = $('#fail');

            if (result.success === false) {
                this.quantityErrorText = result.message;
                this.maxQuantityReached = true;
                return;
            } else {
                successMessage.show();
                failMessage.hide();
            }

            // let modal = $('#checkout-modal');
            // modal.modal('show');
            let cartWidget = $('#myCart');
            cartWidget.trigger('click');
            $(".product-alert").addClass("active");
            setTimeout(function (){
                $(".product-alert").removeClass("active");
            }, 5000);
            window.scrollTo({ top: 0, behavior: 'smooth' });

            EventManager.fire('item-added', result.cart);

            let currentCart = JSON.parse(result.cart);
            var currentVariantInCart = currentCart.rows.map(function (row){
                if(row.variantId = _self.selectedVariant.id && _self.selectedStore.id == currentCart.selectedStore.id){
                    return row.quantity;
                }
            })
            _self.selectedVariant.quantityInCart[_self.selectedStore.id] = currentVariantInCart;

            let event = {
                event: 'eec.add',
                eventData: result.variant,
                unique_event_id: result.unique_event_id,
                action: _self.context,
            }
            EventManager.fire('gtm-event', event);

        },
        updateRequestForm(variantId, storeId){
            document.getElementById("variant_id").value = variantId;
            document.getElementById("store_id").value = storeId;
        },
        selectStore(store) {
            this.selectedStore = store;
            this.fixSelectedQuantity();
        },
        getAttributeImage(option) {
            let _self = this;
            let variantImage = this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, [option])) {
                    if(variant && variant.mediaItem){
                        return '/media/cache/product_view_slider_thumb/' + variant.mediaItem.imageFileName;
                    }
                }
            });

            for (let image in variantImage){
                if(variantImage[image]){
                    return variantImage[image];
                }
            }

            return false;
        },
        getSelectedVariantQuantityStep() {
            if(this.userIsTrader){
                return this.selectedVariant.quantityStepTrader;
            }else{
                return this.selectedVariant.quantityStep;
            }
        }
    },

    computed: {
        selectedVariantPrice() {
            if (this.selectedVariant) {
                return this.formattedPrice(this.selectedVariant.price);
            } else {
                return this.formattedPrice(this.defaultVariant.price);
            }
        }
        ,
        selectedVariantDiscountedPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.discountedPrice) {
                return this.formattedPrice(variant.discountedPrice);
            } else {
                return false;
            }
        }
        ,
        selectedTotalPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.discountedPrice) {
                return this.formattedPrice(variant.discountedPrice * this.quantity);
            } else {
                return this.formattedPrice(variant.price * this.quantity);
            }
        }
        ,
        selectedVariantQuantity() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if(this.selectedStore){
                let quantityInCart = variant.quantityInCart[this.selectedStore.id];

                return this.selectedStore.quantity - quantityInCart;
            }

            return variant.maxQuantity;
        },
        selectedVariantQuantityClean() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if(this.selectedStore){
                return this.selectedStore.quantity;
            }

            return variant.maxQuantity;
        },
        selectedVariantAvailableQuantity() {
            let variant = this.selectedVariant;
            if (variant) {
                if(this.selectedStore){
                    return this.selectedStore.quantity;
                }
                if (this.selectedVariantQuantityClean === 0 && variant.quantity > 0) {
                    this.maxQuantityReached = true;
                }

                return variant.quantity;
            }
            return 0;
        },
        selectedVariantSku() {
            if (this.selectedVariant) {
                return this.selectedVariant.sku
            }
            return null;
        }
        ,
    }
})
;