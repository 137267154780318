import PerfectScrollbar from 'perfect-scrollbar';
import $ from "jquery";

$(document).ready(function () {

    $('.carousel').carousel({
        interval: 5000,
        touch: true
    });

    $(window).scroll(function () {
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 100)
            sticky.addClass('fixed');
        else
            sticky.removeClass('fixed');
        $(".christmas-header").removeClass('fixed')
    });

    $(".delivery-method.option").click(function () {
        $(this).addClass("active")
    })

    $(".sec-type-btn").click(function () {
        $(".products-list.first-type").removeClass("active").addClass("hide")
        $(".products-list.sec-type").addClass("active").removeClass("hide")
        $(".sec-type-btn svg").addClass("active")
        $(".first-type-btn svg").removeClass("active")
    })


    $(".first-type-btn").click(function () {
        $(".products-list.sec-type").removeClass("active").addClass("hide")
        $(".products-list.first-type").addClass("active").removeClass("hide")
        $(".first-type-btn svg").addClass("active")
        $(".sec-type-btn svg").removeClass("active")
    })
    //
    // $(".product-view-page .product-view-body .other-options .options a").click(function () {
    //     $(".product-view-page .product-view-body .other-options .options a").removeClass("active")
    //     $(this).toggleClass("active")
    // })

    $(".offices-delivery .option").click(function () {
        $('.offices-delivery .option.active').removeClass('active');
        $(this).toggleClass("active");
        if($(".offices-delivery .option").hasClass("active")){
            $(".option").addClass("resize");
        }

        if($(this).hasClass("econt")){
            $(".offices-delivery .hidden-info").removeClass("active");
            $(".delivery-to-econt").addClass("active");
        }

        else if($(this).hasClass("address")){
            $(".offices-delivery .hidden-info").removeClass("active");
            $(".delivery-to-address").addClass("active");
        }
    });

    // Filter Products On Mobile

    $(".mobile-list-menu a").click(function (e) {
        e.preventDefault();
        $(".attributes").toggle("slow");
    });


    // Turn On Modal Cart on Click

    $("#myCart").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".modal-cart").toggleClass("active");
    });

    $(".modal-cart").click(function (e) {
        e.stopPropagation();
    });
    $(window).click(function () {
        $('.modal-cart').removeClass('active');
        $(".product-alert").removeClass("active");
    });


    const cookieBox = document.querySelector(".cookies"),
        buttons = document.querySelectorAll(".button");

    const executeCodes = () => {
        if (document.cookie.includes("codinglab")) return;
        cookieBox.classList.add("show");
        buttons.forEach((button) => {
            button.addEventListener("click", () => {
                cookieBox.classList.remove("show");

                if (button.id === "accept-cookies") {
                    document.cookie = "cookieBy= codinglab; max-age=" + 60 * 60 * 24 * 30;
                }
            })
        })
    }

    window.addEventListener("load", executeCodes);

    //Created Demo -> Active Class on Product View Page ->  Options Colors
    $(".color-box").click(function (e) {
        e.preventDefault();
        $(".choose-colors").find(".color-box.active").removeClass("active");
        $(this).addClass("active");
    });

    // Change active state on Product View Page

    $(".title-links a").click(function (e) {
        e.preventDefault();
        $(".title-links").find("a.active").removeClass("active");
        $(this).addClass("active");
        if($(this).hasClass("descriptions")){
            $(".product-view-footer form").removeClass("active");
            $(".description-characteristics").addClass("active");
            $('.description-characteristics iframe').each(function () {
                $(this).attr('src', $(this).attr('src'));
            });
        } else{
            $(".description-characteristics").removeClass("active");
            $(".product-view-footer form").addClass("active");
        }

    });

    //Add Slick Carousel in Home Page
    $(".slick-prev").click(function (e) {
        e.preventDefault();
        $(".manufacturers-slider").slick("slickPrev");
    });

    $(".slick-next").click(function (e) {
        e.preventDefault();
        $(".manufacturers-slider").slick("slickNext");
    });

    if ($(".home-page").length || $('.product-view-page').length) {
        $(".products-list-holder").slick({
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }



    if ($(".manufacturers-slider").length) {
        $(".manufacturers-slider").slick({
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 15,
            slidesToScroll: 15,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 12,
                        slidesToScroll: 12,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 11,
                        slidesToScroll: 11,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 9,
                        slidesToScroll: 9,
                        infinite: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }


    let productsCarousel = $('.owl-products').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 2,
                nav: false,

            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            940: {
                items: 4,
                nav: true,
                stagePadding: 60
            },
            1200: {
                items: 6,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true,
                margin: 30,
            }
        }
    });

    $('.manufacturers-carousel').owlCarousel({
        loop: false,
        margin:10,
        nav: false,
        dots: true,
        stagePadding: 40,

        responsive:{
            0:{
                items:3,
                stagePadding: 35,
                margin: 10
            },
            575:{
                items:4,
            },
            768:{
                items:6,
                stagePadding: 40
            },
            1024:{
                items:7,
                stagePadding: 120
            },
            1100:{
                items:8
            },
            1500:{
                items:10
            }
        }
    })

    const throttleFunction = (func, delay) => {

        // Previously called time of the function
        let prev = 0;
        return (...args) => {
            // Current called time of the function
            let now = new Date().getTime();

            // Logging the difference between previously
            // called and current called timings

            // If difference is greater than delay call
            // the function again.
            if (now - prev > delay) {
                prev = now;

                // "..." is the spread operator here
                // returning the function with the
                // array of arguments
                return func(...args);
            }
        }
    }

    function triggerOnScroll(owl, e) {
        let deltaX = e.originalEvent.deltaX;
        let deltaY = e.originalEvent.deltaY;

        if (deltaY <= -1 || deltaY >= 1) {
            return;
        }
        if (deltaX > 0) {
            productsCarousel.trigger('next.owl');
        } else {
            productsCarousel.trigger('prev.owl');
        }
        e.preventDefault();
    }

    productsCarousel.on('wheel', '.owl-stage', throttleFunction((e) => {
        triggerOnScroll(productsCarousel, e);
    }, 1000));

    $('.owl-products-no-clone').owlCarousel({
        rewind: true,
        loop: false,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 2,
                nav: false,
            },
            360: {
                items: 2,
                nav: false,
            },
            600: {
                items: 3,
                nav: false,
                stagePadding: 80
            },
            940: {
                items: 4,
                nav: true,
                stagePadding: 60
            },
            1200: {
                items: 5,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true,
                margin: 30,
            }
        }
    });

    $('.slider-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })


    $('.product-slide-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                stagePadding: 30,
                nav: false,
                items:1
            },
            426:{
                stagePadding: 30,
                nav: false,
                items:2
            },
            660:{
                stagePadding: 40,
                nav: false,
                items:3
            },
            767:{
                items:3
            },
            796:{
                items:4
            },
            1150:{
                items:5
            },
            1316:{
                items:6
            }
        }
    })


    $('.slide-offer .owl-carousel').owlCarousel({

        loop:false,
        margin:10,
        nav:false,
        responsive:{
            0:{
                stagePadding: 50,
                items:1
            },
            450:{
                stagePadding: 20,
                items:2
            },
            580:{
                stagePadding: 30,
                items:3
            }
        }
    })

    // $(".intro-bg .download-btn").click(function () {
    //     const textElement = document.getElementById("form-offset")
    //     textElement.scrollIntoView()
    // })


    $("header .navbar-toggler.open-mobile-menu, .close-mobile-menu").click(function () {

        $(".mobile-menu").toggleClass("active")
    })

    if ($(window).width() < 1200)
    {
        // $("nav .nav-item.dropdown ").click(function () {
        //     $("nav .nav-item.dropdown .dropdown-menu").removeClass("show")
        //     $(this).toggleClass("show");
        //     $("nav .nav-item.dropdown.show .dropdown-menu").addClass("show")
        //     $("nav .nav-item.dropdown.show .dropdown-menu").addClass("show")
        //
        // })

        $("nav .nav-item.dropdown .sub-menu-sec > button, nav .nav-item.dropdown .sub-menu-sec > button .dropdown-btn").click(function () {
            // $("nav .nav-item.dropdown.show").removeClass("show");

            $(this).parent().toggleClass("show");

        })

        $("nav .nav-item.dropdown .dropdown-toggle, nav .nav-item.dropdown .dropdown-btn").click(function () {
                // $("nav .nav-item.dropdown.show").removeClass("show");

                $(this).parent().toggleClass("show");

        })

    }
    else {
        $("nav .nav-item.dropdown ").mouseenter(function () {
            $("nav .nav-item.dropdown .dropdown-menu").removeClass("show");
            $(this).toggleClass("show");
            $("nav .nav-item.dropdown.show .dropdown-menu").addClass("show");
        }).mouseleave(function () {
            $("nav .nav-item.dropdown .dropdown-menu").removeClass("show");
            $(this).removeClass("show");
        });
    }

    $(".toggle-filters").click(function () {
        $(".products-listing .products-listing-view-holder .attributes").toggleClass("d-block")
    })

    $(".bottom-header .navbar .nav-item.dropdown.show .sub-menu-link .dropdown-btn").click(function () {
        $(".bottom-header .navbar .nav-item.dropdown.show .sub-menu-link .dropdown-btn").removeClass("show");
        $(this).toggleClass("show")
    })


    $(".contacts #contact_us_file1, .contacts #contact_us_file2, .contacts #contact_us_file3").change(function(){
        $(this).parent().parent().addClass("file-added")
    });

});


$(document).ready(function() {
    var fileInput = document.getElementById('contact_us_file1');
    if(fileInput){
        fileInput.addEventListener('change', function() {
            var progressBar = document.querySelector('.custom-file-upload.first .progress-bar');
            progressBar.classList.add('full-width');
            setTimeout(function() {
                progressBar.classList.remove('full-width');
            }, 1000);
        });
    }

    var fileInput2 = document.getElementById('contact_us_file2');
    if(fileInput2){
        fileInput2.addEventListener('change', function() {
            var progressBar = document.querySelector('.custom-file-upload.sec .progress-bar');
            progressBar.classList.add('full-width');
            setTimeout(function() {
                progressBar.classList.remove('full-width');
            }, 1000);
        });
    }

    var fileInput3 = document.getElementById('contact_us_file3');
    if(fileInput3){
        fileInput3.addEventListener('change', function() {
            var progressBar = document.querySelector('.custom-file-upload.third .progress-bar');
            progressBar.classList.add('full-width');
            setTimeout(function() {
                progressBar.classList.remove('full-width');
            }, 1000);
        });
    }

    $('#info-modal').modal('show');

    $('.show-all-results').on('click', function (){
       $(this).parents('table').find('tr').removeClass('first-hidden');
    });


});

$(window).scroll(function () {
    if ($(window).scrollTop() >= 100) {
        $('.back-to-top').show();
    } else {
        $('.back-to-top').hide();
    }
});

$('.back-to-top').click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
})



// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}


document.addEventListener('DOMContentLoaded', function() {
    var moreInfoLink = document.querySelector('.more-info');

    if(moreInfoLink){
        moreInfoLink.addEventListener('click', function(event) {
            event.preventDefault();
            var targetId = this.getAttribute('data-target');
            var target = document.getElementById('detailsBtn');

            if (target) {

                target.scrollIntoView({ behavior: 'smooth', block: 'start' });

                setTimeout(function() {
                    var collapseTarget = document.querySelector(targetId);
                    if (collapseTarget) {
                        collapseTarget.classList.add('show');
                    }
                }, 500);
            }
        });
    }
});

