import { Controller } from "stimulus"

export default class extends Controller {
    submitApplication(event) {
        event.preventDefault();

        if (!this.element.querySelector('form').checkValidity()) {
            this.element.querySelector('form').reportValidity();
            return;
        }

        let submitUrl = this.data.get('submitUrl'),
            formData = new FormData(this.element.querySelector('form')),
            _self = this;
        let submitButton = event.target;
        submitButton.setAttribute('disabled', true);

        $.ajax({
            url: submitUrl,
            data: formData,
            method: 'POST',
            processData: false,
            contentType: false,
            success: function (response) {
                if (response.success) {
                    if (_self.redirectUrl) {
                        window.location.href = _self.redirectUrl;
                    } else {
                        _self.element.innerHTML = response.content;
                    }
                } else {
                    _self.element.innerHTML = response.content;
                }
            },
            error: function (error) {
                submitButton.removeAttribute('disabled');
                console.log(error);
            }
        });

    }

    get redirectUrl() {
        return this.data.get('redirectUrl');
    }
}