import Vue from 'vue/dist/vue.esm.js';
import Settle from '../infrastructure/settle.acceptance.min';

Vue.component('settle', {
    template: '#settle',
    delimiters: ['[[', ']]'],
    props: {
        currency: {
            required: true,
            type: String,
        },
        locale: {
            required: true,
            type: String,
        },
        purchase: {
            required: true,
            type: Object,
        },
        updateDataUrl: {
            required: true,
            type: String,
        },
        pollUrl: {
            required: true,
            type: String,
        },
        pushUrl: {
            required: true,
            type: String,
        },
    },

    created() {
        this.isLoading = true;
    },

    mounted() {
        this.isLoading = false;
        this.init();
        this.startPolling();
    },

    beforeDestroy () {
        clearInterval(this.polling)
    },

    data() {
        return {
            isLoading: false,
            showPaymentActions: true,
            paymentParams: this.purchase,
            polling: null
        }
    },
    methods: {

        async postData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'

                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },
        sendPush(){
            this.isLoading = true;

            let _self = this;
            _self.postData(_self.pushUrl, []).then(function (response) {
                console.log(response);
               /* _self.isLoading = response.loading;
                _self.showPaymentActions = !response.loading;
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }*/
            });

        },
        init() {
            Settle.displayQRorButton();
            this.poll();

        },

        startPolling(){
            this.polling = setInterval(() => {
               this.poll();
            }, 3000)
        },

        poll(){
            let _self = this;
            _self.postData(_self.pollUrl, []).then(function (response) {
                _self.isLoading = response.loading;
                _self.showPaymentActions = !response.loading;
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            });
        },


    }
});