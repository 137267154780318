import Vue from 'vue/dist/vue.esm.js';

Vue.component('review-modal', {
    template: '#review-modal',
    delimiters: ['[[', ']]'],
/*    props: {
        product: {
            required: true,
            type: Object,
        },
    },*/

    mounted() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if(urlSearchParams.has('review')){
            $('#add-review').modal('show');
        }
    },

/*    data() {
        return {
            count: this.initialCount,
        }
    },*/

    methods: {
        favoriteAdded() {
            this.count = this.count + 1;
        },
        favoriteRemoved() {
            this.count = this.count - 1;
        },

    }
});