import Vue from 'vue/dist/vue.esm.js';

Vue.component('user-address', {
    template: `#user-address`,
    delimiters: ['[[', ']]'],
    props: {
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
        deliveryTypes: {
            required: true,
            type: Array,
        },
    },

    mounted() {
        var selectedDeliveryTypeId = (this.$refs['selectedOption'].dataset.type);
        let _self = this;
        this.deliveryTypes.forEach(function (el){
            if(el.id == selectedDeliveryTypeId){
                _self.selectedType = el;
            }
        })
        this.selectDeliveryType(_self.selectedType);
    },


    data() {
        return {
            selectedCountry: null,
            selectedType: null,
            selectedArea: null,
            selectedOption: null,
            selectedSlot: null,
            selectedCity: null,
            isSlotSelecting: false,
            noOfficesWarning: false,
        }
    },

    methods: {
        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },
        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        formattedDate(datetimeString) {
            let date = new Date(datetimeString);
            return new Intl.DateTimeFormat(this.locale, {day: 'numeric', month: 'numeric', weekday: 'long'}).format(date);
        },
        selectDeliveryType(deliveryType){
            this.selectedOption = deliveryType.areas[0].options[0];
            this.selectedType = deliveryType;
        }

    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },
        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            return this.deliveryPrice;
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal - (this.cart.originalTotal / 6));
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.freeDeliveryOver - this.cart.total);
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
    }
});