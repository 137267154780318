import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('nag')) {
            this.acceptAgeGate();
        }

        if (!this.getCookie("SWAG")) {
            $(this.element).modal('show');
        }
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    acceptAgeGate() {

        if (this.getCookie("SWAG")) {
            return;
        }

        let d = new Date();
        d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "SWAG=1;" + expires + ";path=/";

        $(this.element).modal('hide');

    }

    reload() {
        window.location.reload();
    }

}
