import {Controller} from 'stimulus'
import {useIntersection} from 'stimulus-use'

export default class extends Controller {
    connect() {
        this.promotionId = this.element.dataset.promotionId;
        this.promotionName = this.element.dataset.promotionName;
        this.promotionCreative = this.element.dataset.promotionCreative;
        this.position = this.element.dataset.position;

        useIntersection(this)
    }

    appear(entry) {
        let data = {
            id: this.promotionId,
            name: this.promotionName,
            creative: this.promotionCreative,
            position: this.position
        }
        EventManager.fire('promotion-appear', data);
    }
}