import Vue from 'vue/dist/vue.esm.js';

Vue.component('filter-widget', {
    template: '#filter-widget',
    delimiters: ['[[', ']]'],
    props: {
        title: {
            required: true,
            type: String,
        },
        property: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
        },
        entries: {
            required: true,
            type: Array,
        },
    },

    mounted() {

        EventManager.listen('filter-removed', this.filterRemoved);

        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get(this.property)) {
            this.open = true;

            let _self = this;
            this.availableEntries.map(function (entry) {
                let selectedProperties = searchParams.get(_self.property).split(',');
                for (let propertyValue of selectedProperties) {
                    if (entry.id == propertyValue) {
                        entry.selected = true;
                        EventManager.fire('filter-applied', {entry: entry, title: _self.title, property: _self.property});
                    }
                }
            });
        }
    },

    data() {
        return {
            availableEntries: this.entries,
            term: null,
            open: false,
        }
    },

    methods: {
        search() {
            if (this.term === '') {
                this.availableEntries = this.entries;
                return;
            }
            this.availableEntries = this.entries.filter(e => e.value.toLowerCase().search(this.term.toLowerCase()) !== -1);
        },
        rebuildQuery() {
            let searchParams = new URLSearchParams(window.location.search);

            let selectedEntriesIds = this.availableEntries.filter(e => e.selected);

            let ids = [];
            for (let entry of selectedEntriesIds) {
                ids.push(entry.id);
            }

            if (ids.length) {
                searchParams.set(this.property, ids.join(','));
            } else {
                searchParams.delete(this.property);
            }

            searchParams.delete('page');

            history.replaceState(null, null, ' ');
            window.location.search = searchParams.toString();
        },

        selectEntry(entry) {
            entry.selected = !entry.selected;
            this.rebuildQuery();
        },

    }
});