import Vue from 'vue/dist/vue.esm.js';

Vue.component('add-all-product-rows', {
    template: `#add-all-product-rows`,
    delimiters: ['[[', ']]'],

    methods: {
        triggerAllRowsAddToCart(){
            EventManager.fire('add-all-rows');
        }
    }
});