import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    connect() {
        this.productId = this.element.dataset.productId;
        this.productName = this.element.dataset.productName;
        this.productCategory = this.element.dataset.productCategory;
        this.productVariant = this.element.dataset.productVariant;
        this.productManufacturer = this.element.dataset.productBrand;
        this.position = this.element.dataset.position;
        this.list = this.element.dataset.list;

        useIntersection(this)
    }

    appear(entry) {
        let data = {id: this.productId, name: this.productName, category: this.productCategory, variant: this.productVariant, brand: this.productManufacturer, position: this.position, list: this.list }
        EventManager.fire('impression-appear', data);
    }
}