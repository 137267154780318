import Vue from 'vue/dist/vue.esm.js';

Vue.component('cart-widget', {
    template: `#cart-widget`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        stores: {
            required: false,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeCartRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        changeStoreUrl: {
            required: true,
            type: String,
        },
        hasUser: {
            required: false,
            type: Number,
        },
        amountForFreeShipping: {
            required: false,
            type: Number,
        },
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
        EventManager.listen('store-change', this.cartUpdated);

        this.selectedStore = this.cart.selectedStore;
    },

    data() {
        return {
            selectedStore: null,
            cart: this.initialCart,
            loading: false
        }
    },

    methods: {
        cartUpdated(data) {
            this.cart = JSON.parse(data);
        },

        removeRow(rowId, removeItemUrl) {
            this.buildAjax(rowId, removeItemUrl);
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

        buildAjax(rowId, url) {
            let _self = this
            $.ajax({
                data: {id: rowId},
                url: url,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        successAjax(result) {
            if (result.success === false) {
                return;
            }
            EventManager.fire('variant-change', result.variant);
            EventManager.fire('item-removed', result.cart);

            let event = {
                event: 'eec.remove',
                eventData: result.variant,
                action: 'CartWidget',
            }
            EventManager.fire('gtm-event',event);
        },
        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index,attribute] of attributes.entries()) {
                if(index !== 0){
                    str += ', '
                }
                if(attribute.showUnit){
                    str += attribute.unit + ' x '
                }
                str +=  ' ' + attribute.value;

            }
            return str;
        },
        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('variant-change', result.variant);

                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event',event);
                },
            });
        },
        changeCartStore() {
            let _self = this;
            this.loading = true;
            $.ajax({
                data: {id: _self.selectedStore.id},
                url: _self.changeStoreUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('store-change', result.cart);
                    _self.loading = false;
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId,storeId: _self.selectedStore.id,},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {

                    if(result.success !== false){
                        EventManager.fire('item-added', result.cart);
                        EventManager.fire('variant-change', result.variant);

                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            unique_event_id: result.unique_event_id,
                            action: 'CartPage',
                        }

                        EventManager.fire('gtm-event', event);

                    }
                },
            });
        },
        selectStore(store){
            this.selectedStore = store;
            this.changeCartStore();
        },
        hasRowsForStore(store){
            return this.cart.rows.filter(row => row.store.id == store.id).length > 0;
        }
    },

    computed: {
        total() {
            return this.formattedPrice(this.cart.totalPriceAfterDiscount)
        },
        selectedStoreRows(){
            let _self = this;
            if(!_self.selectedStore){
                return this.cart.rows;
            }

            if(this.cart.rows.length == 0){
                return [];
            }
            if(!Array.isArray(this.cart.rows)){
                this.cart.rows = Object.values(this.cart.rows)
            }

            return this.cart.rows.filter(row => row.store.id === _self.selectedStore.id);
        },
        selectedStoreRowsQuantity(){
            let _self = this;
            if(!_self.selectedStore){
                return this.cart.rows;
            }
            let storeRowsQuantity = 0;
            this.selectedStoreRows.forEach((el) => storeRowsQuantity += el.quantity);

            return storeRowsQuantity;
        }
    }
});