import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['element'];
    static values = { store: String };

    connect() {
        this.elementTarget.classList.add('d-none');
    }

    toggle() {
        this.elementTarget.classList.toggle('d-none');
    }
}