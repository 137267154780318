import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['file']

    connect() {
    }
    openChooseFileModal(){
        this.fileTarget.click();
    }
    fileSelected(){
        $('#selectedFileName').text(this.fileTarget.files.item(0).name);
        $('#fastOrderFileSelected').modal('show');
    }
    submitFile() {
        $('#fastOrderForm').submit();
    }
}
