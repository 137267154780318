import Vue from 'vue/dist/vue.esm.js';

Vue.component('checkout-guest', {
    template: `#checkout-guest`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        deliveryTypes: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
        uniqueEventId: {
            required: false,
            type: String,
        },
        step: {
            required: false,
            type: Number,
            default: 1
        },
    },

    mounted() {
        if(this.$refs['selectedOption']){
            var selectedDeliveryTypeId = (this.$refs['selectedOption'].dataset.type);
            let _self = this;
            this.deliveryTypes.forEach(function (el){
                if(el.id == selectedDeliveryTypeId){
                    _self.selectedType = el;
                }
            })
            this.selectDeliveryType(_self.selectedType);
        }

        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
        EventManager.listen('load-stimulus', this.loadStimulus);
    },


    data() {
        return {
            cart: this.initialCart,
            selectedUserAddress: null,
            selectedPaymentMethod: null,
            prevSelectedPaymentMethod: null,
            allowNewAddress: 0,
            saveAddress: 0,
            selectedOption: null,
            selectedType: null,
            wantCreateUser: false,
            wantInvoice: false
        }
    },

    methods: {
        loadStimulus() {
            let eventStep1 = {
                event: 'eec.checkout',
                eventData: 'Cart Page',
                unique_event_id: this.uniqueEventId,
                action: 1,
                cart: this.initialCart
            }
            EventManager.fire('gtm-event', eventStep1);

            let isGift = 'not a gift';

            let eventStep2 = {
                event: 'eec.checkout',
                eventData: isGift,
                action: 2,
            }
            EventManager.fire('gtm-event', eventStep2);
        },
        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },
        agreeTerms() {
            let eventStep7 = {
                event: 'eec.checkout',
                eventData: "terms and conditions",
                action: 7,
            }
            EventManager.fire('gtm-event', eventStep7);
        },
        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },
        selectDeliveryType(deliveryType){
            this.selectedOption = deliveryType.areas[0].options[0];
            this.selectedType = deliveryType;
        }
    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },

        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            return this.deliveryPrice;
        },

        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal)
        },
        amountToFreeShipping() {
            return 0;
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        userChosenAddress(){
            //TODO .map by selected from user
            return this.userAddresses[0];
        }
    }
});