import Vue from 'vue/dist/vue.esm.js';

Vue.component('checkout', {
    template: `#checkout`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        editDataUrl: {
            required: false,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
        uniqueEventId: {
            required: false,
            type: String,
        },
        hasUser: {
            required: true,
            type: Number,
        },
        orderAddress: {
            required: false,
            type: Object,
        },
        orderAddressIsOffice: {
            required: false,
            type: Boolean,
        },
        orderAddressIsPhysical: {
            required: false,
            type: Boolean,
        }
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
        EventManager.listen('load-stimulus', this.loadStimulus);
    },


    data() {
        return {
            cart: this.initialCart,
            selectedUserAddress: null,
            selectedPaymentMethod: null,
            prevSelectedPaymentMethod: null,
            allowNewAddress: 0,
            deliveryPrice: null,
            deliveryPriceInCurrentCurrency: null,
            deliveryPriceDiscount: null,
            saveAddress: 0,
            quantityAlert: {
                visible: false,
                productName: '',
            },
        }
    },

    methods: {
        loadStimulus() {
            let eventStep1 = {
                event: 'eec.checkout',
                eventData: 'Cart Page',
                unique_event_id: this.uniqueEventId,
                action: 1,
                cart: this.initialCart
            }
            EventManager.fire('gtm-event', eventStep1);

            let isGift = 'not a gift';

            let eventStep2 = {
                event: 'eec.checkout',
                eventData: isGift,
                action: 2,
            }
            EventManager.fire('gtm-event', eventStep2);
        },
        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },
        agreeTerms() {
            let eventStep7 = {
                event: 'eec.checkout',
                eventData: "terms and conditions",
                action: 7,
            }
            EventManager.fire('gtm-event', eventStep7);
        },

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },
        selectPaymentMethod(paymentMethod) {
            let eventStep6 = {
                event: 'eec.checkout',
                eventData: paymentMethod.method,
                action: 6,
            }
            EventManager.fire('gtm-event', eventStep6);

            if (paymentMethod ===
                this.selectedPaymentMethod) {
                return;
            }
            this.prevSelectedPaymentMethod = this.selectedPaymentMethod;
            this.selectedPaymentMethod = paymentMethod;

        },

        cartUpdated(data) {
            this.cart = JSON.parse(data);
            if (this.cart.rows.length === 0) {
                window.location.href = '/cart';
            }
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);

                    let eventStep1 = {
                        event: 'eec.checkout',
                        eventData: 'Cart Page',
                        action: 1,
                        cart: JSON.parse(result.cart),
                    }

                    EventManager.fire('gtm-event', eventStep1);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success !== false) {
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);

                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            unique_event_id: result.unique_event_id,
                            action: 'CheckoutPage',
                        }
                        EventManager.fire('gtm-event', event);

                        let eventStep1 = {
                            event: 'eec.checkout',
                            eventData: 'Cart Page',
                            action: 1,
                            cart: JSON.parse(result.cart),
                        }

                        EventManager.fire('gtm-event', eventStep1);
                    } else {
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);


                    let eventStep1 = {
                        event: 'eec.checkout',
                        eventData: 'Cart Page',
                        action: 1,
                        cart: JSON.parse(result.cart)
                    }

                    EventManager.fire('gtm-event', eventStep1);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },
        outOfStockProducts() {
            return this.cart.rows.filter(row => !row.inStock);
        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.quantity * row.price);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        qualifiesForFreeDelivery() {
            return false;
        },

        calcDeliveryPrice(price) {
            return this.formattedPrice(price);
        },

        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index, attribute] of attributes.entries()) {
                if (index !== 0) {
                    str += ', '
                }
                if (attribute.showUnit) {
                    str += attribute.unit + ' x '
                }
                str += ' ' + attribute.value;

            }
            return str;
        },

        formattedDate(datetimeString, withYear = false) {

            let date = new Date(datetimeString);
            let dateOptions = {
                day: 'numeric',
                month: 'numeric',
                weekday: 'long'
            };
            if (withYear) {
                dateOptions = {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    weekday: 'long'
                };
            }
            return new Intl.DateTimeFormat(this.locale, dateOptions).format(date);
        },

        calcTotal(includeDelivery) {

            let total = this.cart.total;

            if (includeDelivery) {
                let deliveryPrice = this.deliveryPriceInCurrentCurrency;
                total += deliveryPrice ?? 0;
            }
            return Math.round(total * 100 + Number.EPSILON) / 100;
        },

        shouldShowAddressFields() {
            return false;
        },
    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },

        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            return this.deliveryPrice;
        },

        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal)
        },
        amountToFreeShipping() {
            return 0;
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        formattedTaxPrice() {
            let _self = this;
            var taxPrice = 0;
            if(this.hasUser){
                var taxPriceSettings = this.orderAddress.deliveryOption.taxPrices.filter(taxPriceSettings => (taxPriceSettings.storeId == _self.cart.selectedStore.id));

                if(taxPriceSettings){
                    taxPriceSettings = taxPriceSettings[0];
                }

                if(taxPriceSettings.minTotalForFreeTax >= this.calcTotal()){
                    taxPrice = taxPriceSettings.taxPrice;
                }
            }else{
                if(!this.cart.hasFreeDelivery){
                    taxPrice = this.orderAddress.deliveryOption.price;
                }
            }
            return this.formattedPrice(taxPrice);
        },
        userChosenAddress(){
            //TODO .map by selected from user
            return this.userAddresses[0];
        }
    }
});