import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    connect() {
        this.uniqueEventId = this.element.dataset.uniqueEventId;

        useIntersection(this)
    }

    appear(entry) {
        EventManager.fire('page-view', this.uniqueEventId);
    }
}