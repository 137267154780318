import Vue from 'vue/dist/vue.esm.js';

Vue.component('ajax-pagination', {
    template: `#ajax-pagination`,
    delimiters: ['[[', ']]'],
    props: {
        currentPage: {
            required: true,
            type: Number,
            default: 1
        },
        totalItems: {
            required: true,
            type: Number,
        },
        currentPageItems: {
            required: true,
            type: Number,
        }
    },
    data() {
        return {
            allAjaxResults: '',
            request: false,
            totalShownItems: this.currentPageItems
        }
    },
    methods: {
        getMoreResults() {
            let _self = this;
            let targetUrl = (window.location.href);
            let isSecTypeView = $('.sec-type').hasClass('active');
            if(this.request){
                this.request.abort();
            }
            this.request = $.ajax({
                data: null,
                url: targetUrl + '?page=' + (_self.currentPage + 1) + '&sec-type=' + isSecTypeView,
                method: 'POST',
                cache: false,
                contentType: false,
                processData: false,
                success: function (result) {
                    _self.currentPage++
                    _self.currentPageItems = ($(result).first('.products-list').children().children().length);
                    _self.totalShownItems += _self.currentPageItems;
                    _self.allAjaxResults += result;
                },
            });
        }
    },

    computed: {
        nextPageResults() {
            if((this.totalItems - this.totalShownItems) < this.currentPageItems){
                return (this.totalItems - this.totalShownItems);
            }
            return this.currentPageItems;
        }
    }
});