import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
    }

    initialize() {
        this.url = this.data.get('url');
    }

    submitForm(submitEvent) {
        submitEvent.preventDefault();

        let container = $("#subscription-form-global");

        let formData = [];

        if (container) {
            let form = $('form', container);
            if (!form[0].checkValidity()) {
                form[0].reportValidity();
                return;
            }

            formData = new FormData(form[0]);

            let _self = this;
            $.ajax({
                url: _self.url,
                data: formData,
                method: 'POST',
                processData: false,
                contentType: false,
                success: function (response) {
                    var el = document.createElement('html');
                    el.innerHTML = response;

                    var success = response.success;
                    $('.login-section-holder').css('height', 'auto');
                    if (success) {
                        $('#form-message-error').hide();
                        $('#form-message-success').show();
                    } else {
                        $('#form-message-error').show();
                        $('#form-message-success').hide();
                    }

                },
                error: function (error) {
                    $('#form-message-error').show();
                    $('#form-message-success').hide();
                }
            });

        }
        submitEvent.returnValue = true;
    }
}

