import Vue from 'vue/dist/vue.esm.js';
import $ from "jquery";

Vue.component('landing-page-category-selector', {
    template: `#landing-page-category-selector`,
    delimiters: ['[[', ']]'],


    props: {
        title: {
            required: true,
            type: String,
        },
    },

    mounted() {
    },

    data() {
        return {
            selectedCategory: 0,
        }
    },

    methods: {
        setCategoryIndex(index) {
            this.selectedCategory = index;

            this.$nextTick(() => {
                $('.slide-offer .owl-carousel').owlCarousel({
                    loop: false,
                    margin: 10,
                    nav: false,
                    responsive: {
                        0: {
                            stagePadding: 50,
                            items: 1
                        },
                        450: {
                            stagePadding: 20,
                            items: 2
                        },
                        580: {
                            stagePadding: 30,
                            items: 3
                        }
                    }
                })
            });

        }
    },

});