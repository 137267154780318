import Vue from 'vue/dist/vue.esm.js';
Vue.component('CardForm', {
    template: '#card-form',
    delimiters: ['[[', ']]'],

    directives: {
        'number-only': {
            bind(el) {
                function checkValue(event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }

                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind(el) {
                function checkValue(event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }

                el.addEventListener('keypress', checkValue)
            }
        }
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {
                    cardName: '',
                    cardNumber: '',
                    cardNumberNotMask: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                    saveCard: false,
                }
            }
        },

        hasUser: {
            required: true,
            type: Number,
        },
        onlySave: {
            required: false,
            type: Number,
            default: 0,
        },
        backgroundImage: [String, Object],
        randomBackgrounds: {
            type: Boolean,
            default: true
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        errorMsg: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            fields: {
                cardNumber: 'v-card-number',
                cardName: 'v-card-name',
                cardMonth: 'v-card-month',
                cardYear: 'v-card-year',
                cardCvv: 'v-card-cvv'
            },
            saveCard: false,
            minCardYear: new Date().getFullYear(),
            isCardNumberMasked: true,
            mainCardNumber: this.cardNumber,
            cardNumberMaxLength: 19
        }
    },
    computed: {
        minCardMonth() {
            if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        }
    },
    watch: {
        cardYear() {
            if (this.formData.cardMonth < this.minCardMonth) {
                this.formData.cardMonth = ''
            }
        }
    },
    mounted() {
        this.maskCardNumber()
    },
    methods: {
        generateMonthValue(n) {
            return n < 10 ? `0${n}` : n
        },
        changeName(e) {
            this.formData.cardName = e.target.value
            this.$emit('input-card-name', this.formData.cardName)
        },
        toggleSaveCard(e) {
            this.saveCard = !this.saveCard & 1;
            this.formData.saveCard = this.saveCard;
        },
        changeNumber(e) {
            this.formData.cardNumber = e.target.value
            let value = this.formData.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line eqeqeq
            if (e.inputType == 'deleteContentBackward') {
                let lastChar = this.formData.cardNumber.substring(this.formData.cardNumber.length, this.formData.cardNumber.length - 1)
                // eslint-disable-next-line eqeqeq
                if (lastChar == ' ') {
                    this.formData.cardNumber = this.formData.cardNumber.substring(0, this.formData.cardNumber.length - 1)
                }
            }
            this.$emit('input-card-number', this.formData.cardNumber)
        },
        changeMonth() {
            this.$emit('input-card-month', this.formData.cardMonth)
        },
        changeYear() {
            this.$emit('input-card-year', this.formData.cardYear)
        },
        changeCvv(e) {
            this.formData.cardCvv = e.target.value
            this.$emit('input-card-cvv', this.formData.cardCvv)
        },

        onFormSubmit(e) {
            this.$emit('on-form-submit');
            e.preventDefault();
        },

        invaildCard() {
            let number = this.formData.cardNumberNotMask.replace(/ /g, '')
            var sum = 0
            for (var i = 0; i < number.length; i++) {
                var intVal = parseInt(number.substr(i, 1))
                if (i % 2 === 0) {
                    intVal *= 2
                    if (intVal > 9) {
                        intVal = 1 + (intVal % 10)
                    }
                }
                sum += intVal
            }
            if (sum % 10 !== 0) {
                alert('cardForm.invalidCardNumber')
            }
        },
        blurCardNumber() {
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            }
        },
        maskCardNumber() {
            this.formData.cardNumberNotMask = this.formData.cardNumber
            this.mainCardNumber = this.formData.cardNumber
            let arr = this.formData.cardNumber.split('')
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== '') {
                    arr[index] = '*'
                }
            })
            this.formData.cardNumber = arr.join('')
        },
        unMaskCardNumber() {
            this.formData.cardNumber = this.mainCardNumber
        },
        focusCardNumber() {
            this.unMaskCardNumber()
        },
        toggleMask() {
            this.isCardNumberMasked = !this.isCardNumberMasked
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            } else {
                this.unMaskCardNumber()
            }
        }

    }
});