import Vue from 'vue/dist/vue.esm.js';

Vue.component('active-filters', {
    template: '#active-filters',
    delimiters: ['[[', ']]'],

    mounted() {
        EventManager.listen('filter-applied', this.filterApplied);
    },

    data() {
        return {
            activeFilters: [],
        }
    },

    methods: {
        filterApplied(data) {
            this.activeFilters.push(data);
        },
        removeFilter(filter) {
            let searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get(filter.property)) {
                let values = searchParams.get(filter.property).split(',');

                let newValues = values.filter(i => i != filter.entry.id);
                if (newValues.length) {
                    searchParams.set(filter.property, newValues.join(','));
                } else {
                    searchParams.delete(filter.property);
                }

            }
            window.location.search = searchParams.toString();
        }

    }
});